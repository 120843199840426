import AbstractMapService from "./MapService";
import { MapConfig } from "./MapConfig";

interface MapLocation {
  name?: string;
  city?: string;
  state?: string;
  latitude: number;
  longitude: number;
}
export default class GoogleMapsService extends AbstractMapService {
  private config: MapConfig;
  constructor(config: MapConfig) {
    super();
    this.config = config;
  }
  async getCurrentLocation(): Promise<MapLocation | null> {
    try {
      const position = await this.getCurrentPosition();
      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
    } catch (error) {
      console.error("Error fetching current location:", error);
      return null;
    }
  }
  async searchLocation(placeName: string): Promise<MapLocation | null> {
    try {
      const response = await this.fetchJson(
        `${this.config.locationSearchUrl}${encodeURIComponent(placeName)}&key=${this.config.apiKey}`
      );
      if (response.results && response.results.length > 0) {
        const location = response.results[0].geometry.location;
        return {
          latitude: location.lat,
          longitude: location.lng,
        };
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error searching location:", error);
      return null;
    }
  }
  async fetchPlaceName(
    latitude: number,
    longitude: number
  ): Promise<MapLocation | null> {
    try {
      const response = await this.fetchJson(
        `${this.config.locationNameSearchUrl}${latitude},${longitude}&key=${this.config.apiKey}`
      );
      if (
        response.data &&
        response.data.results &&
        response.data.results.length > 0
      ) {
        return {
          name: response.data.display_name,
          state: response.data.state,
          city: response.data.city,
          latitude,
          longitude,
        };
      } else {
        return null;
      }
    } catch (err) {
      console.error("Error fetching place name:", err);
      return null;
    }
  }
  async getLocation(): Promise<{
    latitude: number;
    longitude: number;
    accuracy: number;
  } | null> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              accuracy: position.coords.accuracy,
            });
          },
          (error) => {
            reject(this.handleGeolocationError(error));
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  }
}
