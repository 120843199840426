import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useForm } from "react-hook-form";
import { Password } from "primereact/password";
import { resetPassword } from "../../app_modules/endpoints";
import { Form } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";

type FormInput = {
  password: string;
  confirm_password: string;
};
const ResetPasswordForm = ({
  visible = true,
  onHide,
}: {
  visible: boolean;
  onHide: () => void;
}) => {
  const { setUser } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  const handleResetPassword = (formData: FormInput) => {
    resetPassword({
      password: formData.password,
    }).then(() => {
      onHide && onHide();
      setUser(null);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.href = "/";
    });
  };

  watch("password");
  return (
    <Dialog
      draggable={false}
      header="Reset Password"
      headerStyle={{
        textAlign: "center",
      }}
      onHide={() => {}}
      visible={visible}
      className="col-10 col-md-4"
    >
      <div className="flex flex-column gap-2 col-12">
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            {...register("password", {
              required: "Password is required",
              onChange: (event) => {
                setValue("password", event.target.value);
              },
            })}
            type="passowrd"
            name="password"
            className="input"
            required={true}
            aria-errormessage={errors?.password?.message}
          />
        </Form.Group>

        {errors.password && (
          <p className="text-sm text-red-500">{errors.password.message}</p>
        )}
        <Form.Group>
          <Form.Label>Confirm password</Form.Label>
          <Form.Control
            {...register("confirm_password", {
              required: "Confirm password is required",
              validate: (value) => {
                return (
                  value === getValues("password") || "Passwords do not match"
                );
              },
              onChange: (event) => {
                setValue("confirm_password", event.target.value);
              },
            })}
            type="passowrd"
            name="confirm_password"
            className="input"
            required={true}
            aria-errormessage={errors?.confirm_password?.message}
          />
        </Form.Group>
        {errors.confirm_password && (
          <p className="text-sm text-red-500">
            {errors.confirm_password.message}
          </p>
        )}

        <Button
          onClick={handleSubmit(handleResetPassword)}
          severity="info"
          rounded
        >
          Reset
        </Button>
      </div>
    </Dialog>
  );
};

export default ResetPasswordForm;
