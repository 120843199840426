import { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import DataTableTwo from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { withTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";

const MembersListComponent = ({ members, onRemove, t }: any) => {
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const [visible, setVisible] = useState<boolean>(false);
  const [currentMember, setCurrentMember] = useState<any>({});
  const accept = () => {
    onRemove(currentMember);
    toast.current?.show({
      severity: "success",
      summary: "Confirmed",
      detail: `${currentMember.fullname} was removed succesfully`,
      life: 3000,
    });
  };
  const columns = [
    {
      name: t("common:name"),
      selector: (row: any) => row.full_name,
      cell: (row: any) => (
        <Link className="remove-anchor-text-deco-member " to={``}>
          {row.full_name}
        </Link>
      ),
    },
    {
      name: t("common:date_of_birth"),
      selector: (row: any) => {
        const dob = new Date(row.dob);
        const dobArr = dob.toDateString().split(" ");
        return `${dobArr[2]} ${dobArr[1]} ${dobArr[3]}`;
      },
    },
    {
      name: t("common:gender"),
      selector: (row: any) => {
        return row.gender.charAt(0).toUpperCase() + row.gender.slice(1);
      },
    },
    {
      name: t("common:traditional_authority"),
      selector: (row: any) => row.location,
    },
    {
      name: t("common:phone_number"),
      selector: (row: any) => row.phone_number as string,
    },
    ...(isAdmin
      ? [
          {
            name: t("common:edit_label"),
            cell: (row: any) => {
              return (
                <EditButton
                  onClick={() => navigate(`/modules/members/${row.id}`)}
                  title="Edit"
                />
              );
            },
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            name: t("common:remove_label"),
            cell: (row: any) => {
              if (!isAdmin) {
                return;
              }
              return (
                <RemoveButton
                  onClick={() => removeMember(row)}
                  title="Remove"
                />
              );
            },
          },
        ]
      : []),
  ];

  const [membersList, setMembers]: any = useState([]);
  const toast: any = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setMembers(members);
    setIsLoading(false);
  }, [members]);

  const RemoveButton = ({ onClick }: any): any => (
    <img
      alt="profile"
      width="25%"
      src={process.env.PUBLIC_URL + `/modules_images/delete-1.png`}
      style={{ cursor: "pointer", borderRadius: "50%" }}
      onClick={onClick}
    />
  );

  const EditButton = ({ onClick }: any): any => (
    <img
      alt="profile"
      width="25%"
      src={process.env.PUBLIC_URL + `/modules_images/edit-1.png`}
      style={{ cursor: "pointer", borderRadius: "50%" }}
      onClick={onClick}
    />
  );

  const removeMember = (member: any): void => {
    setVisible(true);
    setCurrentMember(member);
  };

  const exportExcel = (): void => {
    const worksheet: any = XLSX.utils.json_to_sheet(buildExcelData());
    const workbook: any = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `Famers Export - ${new Date()}.xlsx`);
  };

  const exportCsv = (): void => {
    const csv: any = jsonToCSV(buildExcelData());
    const blob: any = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `Famers Export - ${new Date()}.csv`);
  };

  const jsonToCSV = (json: any): string => {
    const headers: any = Object.keys(json[0]);
    const csvRows: any = [
      headers.join(","),
      ...json.map((row: any) =>
        headers
          .map((header: any) => {
            return row[header];
          })
          .join(",")
      ),
    ];
    return csvRows.join("\n");
  };

  const buildExcelData = (): any[] => {
    return membersList.map((data: any): any => {
      return {
        fullname: `${data.member?.first_name} ${data.member?.last_name}` || "",
        gender: data.member?.gender || "",
        nationa_id: data.member?.national_id,
        date_of_birth: formatDate(data.member?.dob) || "",
        email: data.member?.email || "",
        phone_number: data.member?.phone_number || ("" as string),
        secondary_phone_number:
          data.member?.secondary_phone_number || ("" as string),
        district: data.member?.district?.name || "",
        ta: data.member?.traditional_authority?.name || "",
        epa: data.member?.epa?.name || "",
        section: data.member?.section?.name || "",
        cooperative: data.member?.cooperative?.name || "",
        enterprises:
          data.member?.enterprises
            ?.map((enterprise: any) => enterprise.name)
            ?.join(", ") || "",
      };
    });
  };

  const formatDate = (date: any): string => {
    const dateOj = new Date(date);
    const dateArr = dateOj.toDateString().split(" ");
    return `${dateArr[2]} ${dateArr[1]} ${dateArr[3]}`;
  };

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message={`Are you sure you want to remove "${currentMember.full_name}"?`}
        header="Remove Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        style={{ width: "50vw" }}
        breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
      />
      <div className="table-container">
        <>
          {membersList.length > 0 ? (
            <div className="row">
              <div className="col-md-12">
                <div>
                  <h6>{t("eRegistration:export_data")}</h6>
                  <img
                    src={process.env.PUBLIC_URL + `/excel-6.png`}
                    alt={"spreadsheet image"}
                    onClick={exportExcel}
                    style={{ cursor: "pointer", width: "3%" }}
                  />
                  <img
                    src={process.env.PUBLIC_URL + `/csv-2.png`}
                    alt={"spreadsheet image"}
                    onClick={exportCsv}
                    style={{
                      cursor: "pointer",
                      width: "3%",
                      marginLeft: "0.5%",
                      marginRight: "0.5%",
                    }}
                  />
                  <hr />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div>
            <DataTableTwo columns={columns} data={membersList} pagination />
          </div>
        </>
      </div>
    </div>
  );
};

export default withTranslation(["eRegistration", "common"])(
  MembersListComponent
);
