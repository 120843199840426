import React from "react";
import { InputText, InputTextProps } from "primereact/inputtext";
import withErrorMessage from "../UsersComponents/register-form/withErrorMessage";
import style from "./TextInput.module.css";

type TextInputProps = {
  errorMessage?: string;
} & InputTextProps;

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    return (
      <div className={style.container}>
        <InputText {...props} ref={ref} />
      </div>
    );
  }
);

export default withErrorMessage(TextInput);
