import { Dropdown } from "primereact/dropdown";
import { NGOSupportOptions } from "./constants";
import { Button } from "primereact/button";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import style from "./style.module.css";
import TextInput from "../../TextInput";
import { FormInput } from "./types";

const NGOForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormInput>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "ngo_support",
  });
  return (
    <div className="flex flex-column gap-4">
      {fields.map((field, index) => {
        return (
          <div className="flex flex-column gap-2" key={field.id}>
            <div className={style.removeContainer}>
              <Button
                className={style.removeBtn}
                size="small"
                severity="danger"
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            </div>
            <Controller
              control={control}
              rules={{ required: "NGO support area is required" }}
              name={`ngo_support.${index}.category`}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  value={value}
                  onChange={(e) => onChange(e.value)}
                  optionLabel="label"
                  multiple={true}
                  className="w-full md:w-14rem"
                  options={NGOSupportOptions}
                  panelClassName={style.dropdownPanel}
                  placeholder="NGO support"
                  invalid={Boolean(errors.ngo_support?.[index]?.category)}
                />
              )}
            />

            <TextInput
              placeholder="NGO name"
              {...register(`ngo_support.${index}.name`, {
                required: "NGO name is required",
              })}
              invalid={Boolean(errors.ngo_support?.[index]?.name?.message)}
              errorMessage={errors.ngo_support?.[index]?.name?.message}
            />
          </div>
        );
      })}
      <Button
        label="Add"
        severity="info"
        className={style.addBtn}
        onClick={() =>
          append({
            category: "",
            name: "",
          })
        }
      />
    </div>
  );
};

export default NGOForm;
