import "../../App.css";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { User } from "../../context/types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import UserActionBtn from "./Components/UserActionBtn";
import RegisterForm from "../../components/UsersComponents/register-form";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Pagination } from "../../types";

type UserContainerProps = {
  users: User[];
  pagination: Pagination;
  deleteUser: (id: string) => void;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  refetchUsers: () => void;
};

const UserList: FC<UserContainerProps> = ({
  users,
  pagination,
  setPagination,
  deleteUser,
  refetchUsers,
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const confirm2 = (user: User) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => {
        deleteUser(user.id);
      },
    });
  };

  const Actions = (user: User) => {
    return <UserActionBtn user={user} onDelete={() => confirm2(user)} />;
  };

  const columns = [
    {
      field: "first_name",
      header: "First Name",
    },
    {
      field: "last_name",
      header: "Last Name",
    },
    {
      field: "email",
      header: "Email",
    },
    {
      body: Actions,
      header: "Actions",
    },
  ];

  return (
    <div>
      <div className="pl-5 pt-5 silver-line">
        <div>
          <h1 className="text-4xl font-medium text-900 mb-3">Users</h1>
          <div className="font-medium text-500 mb-3">
            <i className="pi pi-users mr-2"></i>
            <span>{pagination.count}</span>
          </div>
          <div>
            <Button onClick={() => setShowForm(true)}>Register</Button>
            <RegisterForm
              visible={showForm}
              defaultRole="farmer"
              onHide={() => {
                setShowForm(!showForm);
                refetchUsers();
              }}
            />
          </div>

          <div>
            <DataTable
              value={users}
              tableStyle={{ minWidth: "60vw" }}
              size="small"
            >
              {columns.map((col, i) => (
                <Column
                  key={i}
                  field={col.field}
                  body={col.body}
                  header={col.header}
                  style={{ minWidth: "50px", wordBreak: "break-word" }}
                ></Column>
              ))}
            </DataTable>

            <Paginator
              first={pagination.offset}
              rows={pagination.limit}
              totalRecords={pagination.count}
              template={{
                layout: "PrevPageLink CurrentPageReport NextPageLink",
              }}
              onPageChange={(e) => {
                setPagination((prevState: Pagination) => ({
                  ...prevState,
                  offset: e.page * prevState.limit,
                }));
              }}
            />
          </div>
          <ConfirmDialog />
        </div>
      </div>
    </div>
  );
};

export default UserList;
