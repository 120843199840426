import { api } from "../apiClient";
import { RegisterFormInput } from "../components/UsersComponents/register-form/types";
import { Cooperative, Enterprise } from "../types";

export const getMemberById = async (id?: string | number) => {
  const response = await api.get(`/members/${id}`);
  return response.data;
};

export const getHouseholdMembers = async (id?: string | number) => {
  const response = await api.get(`/households/${id}/members`);
  return response.data;
};

export const createHousehold = async (params: any) => {
  const response = await api.post(`/households`, params);
  return response.data;
};

export const createHouseholdMember = async (
  id?: string | number,
  params?: any
) => {
  const response = await api.post(`/households/${id}/members`, params);
  return response.data;
};

export const getUsersList = async (offset: number = 0) => {
  const response = await api.get(`users?offset=${offset}`);
  return response.data;
};

export const deleteHousehouldMember = async (id?: string | number) => {
  const response = await api.delete(`/households/${id}/members`);
  return response.data;
};

export const updateHouseholdMember = async (
  id: string | number,
  params: any
) => {
  const response = await api.patch(`/households/${id}/members`, params);
  return response.data;
};

export const getPosts = async (offset: number = 0) => {
  const response = await api.get(`/posts?offset=${offset}`);
  return response.data;
};

export const getPostComments = async (
  id?: string | number,
  offset: number = 0
) => {
  const response = await api.get(`/posts/${id}/comments?offset=${offset}`);
  return response.data;
};

export const createPost = async (params: any) => {
  const response = await api.post(`/posts`, params);
  return response.data;
};

export const deletePost = async (id: string | number) => {
  const response = await api.delete(`/posts/${id}`);
  return response.data;
};

export const getUserRoles = async () => {
  const response = await api.get("roles");
  return response.data;
};

export const searchVillages = async (query: string) => {
  const response = await api.get("villages/search?searchQuery=" + query);
  return response.data;
};

export const getCooperatives = async (): Promise<Cooperative[]> => {
  const response = await api.get<Cooperative[]>("cooperatives");
  return response.data;
};

export const getEnterprises = async () => {
  const response = await api.get<Enterprise[]>("enterprises");
  return response.data;
};
export const createUser = async (payload: any) => {
  const response = await api.post<any>("users", payload);
  return response.data;
};

export const deleteUser = async (id: string) => {
  const response = await api.delete<any>(`users/${id}`);
  return response.data;
};

export const resetPassword = async (data: { password: string }) => {
  const response = await api.post<any>(`auth/reset-password`, data);
  return response.data;
};
