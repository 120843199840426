import { ReactElement } from "react";
import PersonalInfoForm from "./PersonalInfoForm";
import Location from "./Location";
import { MapConfig } from "../../SharedComponents/GeoLocationComponents/MapConfig";
import HouseholdForm from "./HouseholdForm";

export const StandardSteps: React.ReactElement[] = [<PersonalInfoForm />];

export const FORMS_BY_ROLE: { [key: string]: ReactElement[] } = {
  "moa-app-admin": [],
  "fao-staff": [],
  staff: [],
  "extension-farm-worker": [],
  "cooperative-owner": [],
  farmer: [<Location />, <HouseholdForm />],
  other: [],
};

export const openStreetMapConfig: MapConfig = {
  service: "OpenStreetMap",
  center: [-13.2543, 34.3015],
  zoom: 13,
  tileLayerUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  locationSearchUrl:
    "https://nominatim.openstreetmap.org/search?format=json&q=",
  locationNameSearchUrl:
    "https://nominatim.openstreetmap.org/reverse?format=json&lat=",
  attribution: "&copy; OpenStreetMap contributors",
};

export const GenderOptions = [
  { label: "Female", value: "female" },
  { label: "Male", value: "male" },
  { label: "Other", value: "other" },
];

export const RelationShipOption = [
  { label: "Spouse", value: "Spouse" },
  { label: "Child", value: "Child" },
  { label: "Sibling", value: "Sibling" },
  { label: "Relative", value: "Relative" },
];

export const MartialStatusOptions = [
  { label: "Married", value: "Married" },
  { label: "Single", value: "single" },
  { label: "Divorced", value: "divorced" },
  { label: "Widowed", value: "widowed" },
  { label: "Never Married", value: "never married" },
];

export const EducationLevel = [
  { label: "No Education", value: "no education" },
  { label: "Primary School", value: "primary school" },
  { label: "Secondary School", value: "secondary school" },
  { label: "Tertiary Education", value: "tertiary education" },
];

export const NGOSupportOptions = [
  { label: "Crops", value: "Crops" },
  { label: "Extension services", value: "Extension services" },
  { label: "Fisheries", value: "Fisheries" },
  { label: "Land management", value: "Land management" },
  { label: "Livestock", value: "Livestock" },
  { label: "Nutrition", value: "Nutrition" },
  { label: "Research", value: "Research" },
];

export const GovernmentProjects = [
  { label: "AFIKEPO", value: "AFIKEPO" },
  { label: "AGCOM", value: "AGCOM" },
  { label: "AIYAP", value: "AIYAP" },
  { label: "Adaptation Fund", value: "Adaptation Fund" },
  { label: "MASHEP", value: "MASHEP" },
  { label: "MWASIP", value: "MWASIP" },
  { label: "PRIDE", value: "PRIDE" },
  { label: "SAPP", value: "SAPP" },
];
