import { Button } from "primereact/button";
import { useFieldArray, useFormContext } from "react-hook-form";
import SelectButton from "./ControlledSelectButton";
import style from "./MemberCreate.module.css";
import { RelationShipOption } from "./constants";
import CheckBox from "./ControlledCheckBox";
import { InputMask } from "primereact/inputmask";
import TextInput from "../../TextInput";
import { FormInput } from "./types";

type MemberCreateProps = {
  onSubmit?: (data: FormInput) => void;
};

const MemberCreate = ({ onSubmit }: MemberCreateProps) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormInput>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "members",
  });
  return (
    <div className="flex flex-column gap-5">
      {fields.map((field, index) => {
        return (
          <div className={style.container} key={field.id}>
            <div className={style.removeContainer}>
              <Button
                className={style.removeBtn}
                size="small"
                severity="danger"
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            </div>

            <TextInput
              {...register(`members.${index}.first_name`, {
                required: "First name is required",
              })}
              placeholder="First name"
              invalid={Boolean(errors.members?.[index]?.first_name?.message)}
              errorMessage={errors.members?.[index]?.first_name?.message}
            />
            <TextInput
              {...register(`members.${index}.last_name`, {
                required: "Last name is required",
              })}
              placeholder="Last name"
              invalid={Boolean(errors.members?.[index]?.last_name?.message)}
              errorMessage={errors.members?.[index]?.last_name?.message}
            />

            <InputMask
              {...register(`members.${index}.national_id`)}
              placeholder="National ID"
              mask="a*******"
              autoCapitalize="on"
            />

            <SelectButton
              className={style.selectButton}
              name={`members.${index}.relationship`}
              options={RelationShipOption}
              label="Relationship"
              rules={{ required: "Relationship is required" }}
              invalid={Boolean(errors.members?.[index]?.relationship?.message)}
              errorMessage={errors.members?.[index]?.relationship?.message}
            />

            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <i className="pi pi-calendar"></i>
              </span>
              <InputMask
                {...register(`members.${index}.dob`, {
                  required: "Date of birth is required",
                })}
                placeholder="Date of birth"
                mask="99/99/9999"
                slotChar="mm/dd/yyyy"
                invalid={Boolean(errors.members?.[index]?.dob?.message)}
              />
            </div>

            <div className="flex gap-2">
              <CheckBox
                name={`members.${index}.has_disabilities`}
                label="Has disabilities"
              />

              <CheckBox
                name={`members.${index}.farming_participant`}
                label="Farming participant"
              />
            </div>
          </div>
        );
      })}
      <Button
        iconPos="right"
        icon={"pi pi-save"}
        severity="info"
        label="Add member"
        className={style.addBtn}
        onClick={() => {
          append({
            first_name: "",
            last_name: "",
            national_id: "",
            relationship: "",
            dob: "",
            has_disabilities: false,
            farming_participant: false,
          });
        }}
      />
    </div>
  );
};

export default MemberCreate;
