import { RegisterFormProvider } from "./context";
import Modal from "./Modal";

type RegisterFormProps = {
  visible: boolean;
  defaultRole: string;
  onHide: () => void;
};
const RegisterForm = (props: RegisterFormProps) => {
  return (
    <RegisterFormProvider {...props}>
      <Modal />
    </RegisterFormProvider>
  );
};

export default RegisterForm;
