import { SelectButton, SelectButtonProps } from "primereact/selectbutton";
import { Controller, useFormContext } from "react-hook-form";
import withErrorMessage from "./withErrorMessage";

type ControlledSelectButtonProps = {
  name: string;
  label: string;
  errorMessage?: string;
  rules?: any;
} & SelectButtonProps;
const ControlledSelectButton = (props: ControlledSelectButtonProps) => {
  const { options, name, label, rules, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-column gap-2">
      <label>{label}</label>
      <Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <SelectButton
            options={options}
            value={value}
            color="info"
            invalid={Boolean(errors?.[name])}
            onChange={(e) => onChange(e.value)}
            {...rest}
          />
        )}
        name={name}
      />
    </div>
  );
};

export default withErrorMessage(ControlledSelectButton);
