import React, { createContext, useContext, useEffect, useState } from "react";
import { Household, UserRole } from "../../../types";
import { User } from "../../../context/types";
import { getUserRoles } from "../../../app_modules/endpoints";

type RegisterFormContextProps = {
  userRoles: UserRole[];
  user: User | null;
  userRole: UserRole | null;
  visible: boolean;
  households: Household[];
  formCurrentStep: number;
  setUser: (user: User | null) => void;
  setUserRole: (userRole: UserRole) => void;
  setFormCurrentStep: (step: number) => void;
  onHide: () => void;
  addHousehold: (household: Household) => void;
};

interface RegisterFromProviderProps {
  visible: boolean;
  onHide: () => void;
  defaultRole: string;
  children: React.ReactNode;
}

export const RegisterFromContext = createContext<RegisterFormContextProps>({
  userRoles: [],
  user: null,
  userRole: null,
  households: [],
  formCurrentStep: 1,
  setUser: () => {},
  setUserRole: () => {},
  visible: false,
  onHide: () => {},
  addHousehold: (household: Household) => {},
  setFormCurrentStep: (step: number) => {},
});

export function RegisterFormProvider(props: RegisterFromProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [formCurrentStep, setFormCurrentStep] = useState<number>(0);
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [userRole, setUserRole] = useState<UserRole | null>(null);

  const [households, setHouseholds] = useState<Household[]>([]);

  const addHousehold = (household: Household) => {
    setHouseholds([...households, household]);
  };

  useEffect(() => {
    (async () => {
      try {
        const userRoles = await getUserRoles();
        const defaultRole = userRoles.find(
          (role: UserRole) => role.slug === props.defaultRole
        );
        setUserRoles(userRoles);
        setUserRole(defaultRole);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);
  return (
    <RegisterFromContext.Provider
      value={{
        userRoles: userRoles,
        user: user,
        userRole,
        households,
        formCurrentStep,
        setFormCurrentStep,
        addHousehold,
        setUser,
        setUserRole,
        ...props,
      }}
    >
      {props.children}
    </RegisterFromContext.Provider>
  );
}

export const useRegisterForm = () => {
  const context = useContext(RegisterFromContext);

  if (!context) {
    throw new Error(
      "useRegisterForm must be used within an RegisterFormProvider"
    );
  }

  return context;
};
