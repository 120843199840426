import { Dropdown, DropdownProps } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Controller, useFormContext } from "react-hook-form";

type ControlledSelectButtonProps = {
  name: string;
  label: string;
} & DropdownProps;
const ControlledDropdown = (props: ControlledSelectButtonProps) => {
  const { options, name, label, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const Component: React.ComponentType<any> = props.multiple
    ? MultiSelect
    : Dropdown;
  return (
    <div className="flex flex-column gap-2">
      <label>{label}</label>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <Component
            options={options}
            value={value}
            onChange={(e: any) => onChange(e.value)}
            {...rest}
          />
        )}
        name={name}
      />
    </div>
  );
};

export default ControlledDropdown;
