import { AutoComplete, AutoCompleteProps } from "primereact/autocomplete";

import style from "./SearchInput.module.css";
import { useController, useFormContext } from "react-hook-form";
import withErrorMessage from "./withErrorMessage";

type SearchInputProps<T> = {
  searchItems: Array<T>;
  query: string;
  name: string;
  rules?: Record<string, any>;
  errorMessage?: string;
  setQuery: (query: string) => void;
  onNotFoundClick?: () => void;
} & AutoCompleteProps;

const SearchInput = <T,>({
  searchItems,
  query,
  setQuery,
  onNotFoundClick,
  name,
  rules,
  ...rest
}: SearchInputProps<T>) => {
  const { setValue } = useFormContext();
  const {
    field,
    formState: { errors },
  } = useController({ name, rules });

  return (
    <div>
      <AutoComplete
        ref={field.ref}
        panelClassName={style.autocompletePanel}
        field="name"
        className={style.autocomplete}
        value={query}
        placeholder="Search for village"
        suggestions={searchItems}
        completeMethod={(e) => setQuery(e.query)}
        selectedItemTemplate={(suggestion) => <Option item={suggestion} />}
        itemTemplate={(suggestion) => <Option item={suggestion} />}
        showEmptyMessage={true}
        emptyMessage="No results found"
        invalid={Boolean(errors?.[name])}
        onSelect={(e) => {
          const value = e.value;
          setValue(name, value);
          if (value) {
            setQuery(
              `${value.name} - ${value.traditional_authority} - ${value.district}`
            );
          }
        }}
        {...rest}
      />
      <p className={style.notFound}>
        Could not find a village.{" "}
        <span onClick={onNotFoundClick}>Click here</span>
      </p>
    </div>
  );
};

const Option = ({ item }: { item: any }) => {
  return (
    <div>
      <p>
        {item.name} - {item.traditional_authority} - {item.district}
      </p>
    </div>
  );
};
export default withErrorMessage(SearchInput);
