import React from "react";
import style from "./style.module.css";
const withErrorMessage = (Component: React.ComponentType<any>) => {
  return React.forwardRef((props: any, ref) => {
    const { errorMessage, ...rest } = props;
    return (
      <div>
        <Component {...rest} ref={ref} />
        {errorMessage && <p className={style.errorMessage}>{errorMessage}</p>}
      </div>
    );
  });
};

export default withErrorMessage;
