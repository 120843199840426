import { useEffect, useState } from "react";
import ControlledDropdown from "./ControlledDropdown";
import { DropdownProps } from "primereact/dropdown";

type DropdownContainerProps<T> = {
  name: string;
  label: string;
  optionLabel?: string;
  multiple?: boolean;
  fetch: (args?: any) => Promise<T[]>;
} & DropdownProps;

const DropdownContainer = <T,>(props: DropdownContainerProps<T>) => {
  const {
    fetch,
    label,
    name,
    optionLabel = "label",
    multiple,
    ...rest
  } = props;
  const [items, setItems] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      fetch()
        .then((res: unknown[]) => {
          setItems(res as T[]);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    };
    fetchData();
  }, []);

  return (
    <ControlledDropdown
      name={name}
      label={label}
      options={items}
      loading={isLoading}
      optionLabel={optionLabel}
      multiple={multiple}
      {...rest}
    />
  );
};

export default DropdownContainer;
