import { createContext, useContext, useEffect, useState } from "react";
import { User } from "./types";
import { api, post } from "../apiClient";
import { Nav, Spinner } from "react-bootstrap";

interface AuthContextProps {
  user: User | null;
  isAdmin: boolean;
  setUser: (user: User | null) => void;
  setIsAdmin: (isAdmin: boolean) => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  isAdmin: false,
  setUser: () => {},
  setIsAdmin: () => {},
});

export function AuthProvider(props: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      try {
        if (!user) {
          const authResponse = await checkIfAuthenticated();

          const rolesMap = authResponse.data.data.roles.map(
            (role: any) => role.slug
          );

          setUser(authResponse.data.data);
          setIsAdmin(rolesMap.includes("moa-app-admin"));
        }
      } catch (error) {
        console.error(error);
        setUser(null);
        if (token) {
          console.log(error);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          window.location.href = "/";
        }
      }
    })();
  }, []);

  // TODO: Add proper loading page
  if (!user && token) {
    return (
      <div className="flex justify-content-center vh-100">
        <div className="align-self-center">
          <Spinner animation="border" variant="primary" />
          <span className="ms-2 fw-bolder fs-3">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ user, setUser, isAdmin, setIsAdmin }}>
      {props.children}
    </AuthContext.Provider>
  );
}

const checkIfAuthenticated = async () => {
  const response = await api.post("auth/verify");
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  throw new Error("Unauthorized");
};
export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }

  return authContext;
};
