import { Dropdown } from "primereact/dropdown";
import { GovernmentProjects, NGOSupportOptions } from "./constants";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "primereact/button";
import style from "./style.module.css";
import TextInput from "../../TextInput";
import { FormInput } from "./types";

const GovernmentProjectForm = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<FormInput>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "government_support",
  });

  return (
    <div className="flex flex-column gap-4">
      {fields.map((field, index) => {
        return (
          <div className="flex flex-column gap-2" key={field.id}>
            <div className={style.removeContainer}>
              <Button
                className={style.removeBtn}
                size="small"
                severity="danger"
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            </div>

            <Controller
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  value={value}
                  onChange={(e) => onChange(e.value)}
                  optionLabel="label"
                  multiple={true}
                  className="w-full"
                  panelClassName={style.dropdownPanel}
                  options={NGOSupportOptions}
                  placeholder="Support area"
                />
              )}
              control={control}
              name={`government_support.${index}.project_name`}
            />

            <Controller
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  value={value}
                  optionLabel="label"
                  multiple={true}
                  className="w-full"
                  onChange={(e) => onChange(e.value)}
                  panelClassName={style.dropdownPanel}
                  options={NGOSupportOptions}
                  placeholder="Government Project name"
                />
              )}
              control={control}
              name={`government_support.${index}.support_area`}
            />

            <div className="p-inputgroup flex-1">
              <TextInput
                {...register(`government_support.${index}.length_of_support`, {
                  required: "NGO name is required",
                })}
                invalid={Boolean(
                  errors?.government_support?.[index]?.length_of_support
                )}
                placeholder="Length of support"
              />
              <span className="p-inputgroup-addon">months</span>
            </div>
          </div>
        );
      })}

      <Button
        label="Add"
        severity="info"
        className={style.addBtn}
        onClick={() =>
          append({
            project_name: "",
            support_area: "",
            length_of_support: "",
          })
        }
      />
    </div>
  );
};

export default GovernmentProjectForm;
