import { InputText } from "primereact/inputtext";
import { useFormContext } from "react-hook-form";

const LandHoldingForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="flex flex-column gap-2">
      <div className="p-inputgroup flex-1">
        <InputText
          placeholder="Size of the land"
          {...register("size_of_land")}
        />
        <span className="p-inputgroup-addon">hectares</span>
      </div>

      <div className="p-inputgroup flex-1">
        <InputText
          placeholder="Size of the permanently held"
          {...register("size_of_land_permanent")}
        />
        <span className="p-inputgroup-addon">hectares</span>
      </div>
      <div className="p-inputgroup flex-1">
        <InputText
          placeholder="Size of the land being rented"
          {...register("size_of_land_rented")}
        />
        <span className="p-inputgroup-addon">hectares</span>
      </div>
    </div>
  );
};

export default LandHoldingForm;
