//import OptionsData from "./options.json"
import CustomList from "../../components/SharedComponents/NavComponents/OptionsComponents/CustomList";
//import { useState, useEffect } from 'react'
//import { I18nextProvider, useTranslation } from 'react-i18next';
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
// import SidebarReactComponent from "../../components/SharedComponents/NavComponents/SidebarReactComponent";

const SettingsAppModule = () => {
  const items = [
    { label: "Settings" },
    { label: "Language", url: "/modules/settings" },
    { label: "Locations", url: "/modules/settings/geo" },
  ];
  const home = { icon: "pi pi-home", url: "/" };

  const { isAdmin } = useAuth();

  return (
    <div>
      <div className="p-5 silver-line flex flex-column gap-5">
        <BreadCrumb model={items} home={home} />

        <div className="surface-0">
          <div className="text-3xl font-medium text-900 mb-3">
            <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
              <div>
                <div className="text-4xl text-primary font-bold mb-3">
                  Language
                </div>
              </div>
            </div>
          </div>
          <div>
            <CustomList />
          </div>
        </div>
        {isAdmin && (
          <div className="surface-0">
            <div className="text-3xl font-medium text-900 mb-3">
              <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                <div>
                  <div className="text-4xl text-primary font-bold mb-3">
                    User Management
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Link to={"/modules/settings/users"}>
                <Button
                  icon="pi pi-user"
                  severity="info"
                  aria-label="User"
                  className="p-button-rounded p-button-outlined p-5"
                  label="Users"
                />
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsAppModule;
