import { useState } from "react";
import GeoLocationBaseComponent from "../../SharedComponents/GeoLocationComponents/MapBaseComponent";
import MapProvider from "../../SharedComponents/GeoLocationComponents/MapProvider";
import { openStreetMapConfig } from "./constants";
import SearchInput from "./SearchInputContainer";
import {
  LocationArea,
  MapMarkStatus,
} from "../../SharedComponents/GeoLocationComponents/types";
import { useFormContext } from "react-hook-form";
import { FormInput } from "./types";

const Location = () => {
  const [markValue, setMarkValue] = useState<MapMarkStatus>(
    MapMarkStatus.Unmarked
  );
  const [locationAreas, setLocationAreas] = useState<LocationArea>();
  const [showMap, setShowMap] = useState<boolean>(false);

  const {
    formState: { errors },
  } = useFormContext<FormInput>();

  return (
    <div>
      <SearchInput
        name="village"
        rules={{ required: "Village is required" }}
        onNotFoundClick={() => setShowMap(true)}
        disabled={showMap}
        errorMessage={errors?.village?.message}
      />

      {showMap && (
        <MapProvider
          config={openStreetMapConfig}
          mark_value={markValue}
          location_area={locationAreas}
          invokeCancelMapMarker={() => {
            setShowMap(false);
          }}
        />
      )}
    </div>
  );
};

export default Location;
