import "./nav.css";
import "./MainModule.css"; // Import custom CSS for MainModule
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button } from "primereact/button";
// import ForestIcon from '@mui/icons-material/Forest';

import { useAuth } from "../context/AuthContext";
import Statistics from "./StatisticsContainer";
import { APP_MODULES } from "../constants";
import { useEffect, useState } from "react";
import ResetPasswordForm from "../components/UsersComponents/ResetPasswordForm";

type MainModuleProps = {
  t: any;
};

const MainModule = ({ t }: MainModuleProps) => {
  const auth = useAuth();

  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

  useEffect(() => {
    console.log(auth.user);
    if (auth.user && auth.user.reset_password) {
      setShowResetPassword(true);
    }
  }, []);

  const isAuthenticated: boolean = auth.user ? true : false;

  const numLinks = APP_MODULES.length;
  const numColumnsPerRow = numLinks > 3 ? (numLinks > 8 ? 4 : 3) : numLinks;

  const Links = APP_MODULES.map((data: any, index: number) => (
    <div
      className={`col-lg-${12 / numColumnsPerRow} col-md-6 col-sm-6 col-xs-12 mt-2`}
      key={index}
    >
      <div className="p-3 h-full">
        <div
          className="shadow-2 p-3 h-full flex flex-column border border-white border-t border-r border-b border-l border-opacity-25"
          style={{ borderRadius: "8px" }}
        >
          <div className="dIcon p-4 text-center">
            <i
              className={`${data.dicon}`}
              style={{ color: `${data.iconColor}` }}
            ></i>
          </div>
          <div className="text-900 font-bold text-xl text-center mb-2">
            <h1 className="sm:w-2/5 font-medium title-font text-2xl mb-2 sm:mb-0">
              {t(data.name)}
            </h1>
          </div>

          <div className="text-700 text-center">{t(data.description)}</div>

          <hr className="my-3 mx-0 border-top-1 border-bottom-none border-400" />
          <ul className="list-none p-0 m-0 flex-grow-1">
            <li className="flex align-items-center mb-3">
              <i className="pi pi-check-circle text-green-500 mr-2"></i>
              <span>{t(data.objectiveOne)}</span>
            </li>
            <li className="flex align-items-center mb-3">
              <i className="pi pi-check-circle text-green-500 mr-2"></i>
              <span>{t(data.objectiveTwo)}</span>
            </li>
            <li className="flex align-items-center mb-3">
              <i className="pi pi-check-circle text-green-500 mr-2"></i>
              <span>{t(data.objectiveThree)}</span>
            </li>
          </ul>
          <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-400 mt-auto" />
          <Link to={data.to}>
            <Button
              label={t("proceed_button")}
              className="p-3 w-full mt-auto"
            />
          </Link>
        </div>
      </div>
    </div>
  ));

  const bannerSizingDiv = !isAuthenticated
    ? "col-lg-6 col-md-12 col-sm-12 col-xs-12"
    : "col-lg-12 col-md-12 col-sm-12 col-xs-12";
  const bannerSizing = !isAuthenticated
    ? "md:ml-auto block md:h-full"
    : "banner-image-size";
  const bannerSkew = !isAuthenticated
    ? "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)"
    : "";

  return (
    <>
      <div className="row m-auto surface-0 text-800 container">
        {!isAuthenticated ? (
          <div className="col-lg-6 col-md-12 text-center md:text-left flex align-items-center ">
            <section>
              <span className="block text-6xl font-bold mb-1">
                {t("welcome_header")}
              </span>
              <div className="text-6xl text-primary font-bold mb-3">
                {t("dept_title")}
              </div>
              <p className="mt-0 line-height-3 text-900 text-2xl">
                {t("platform_description")}
              </p>
              <Link to="/login">
                <Button
                  label={t("sign_in_label")}
                  type="button"
                  className="mr-3 mb-2 p-button-raised"
                />
              </Link>
              &nbsp;
              <Link to="/auth/register">
                <Button
                  label={t("account_request_button")}
                  type="button"
                  className="mb-2 p-button-outlined"
                />
              </Link>
            </section>
          </div>
        ) : (
          ""
        )}

        <div className={`${bannerSizingDiv} mt-2`}>
          <img
            src="/images/farm-crops.jpg"
            alt="hero-1"
            className={bannerSizing}
            style={{ clipPath: `${bannerSkew}` }}
          />
        </div>
      </div>

      <div className="">
        <div>
          <div className="surface-0">
            <div className="text-900 font-bold text-6xl text-center">
              {/* {navHomePageInfo.module_header} */}
              {t("module_header")}
            </div>
            <div className="text-700 text-xl m-2 text-center line-height-3">
              {/* {navHomePageInfo.module_description} */}
              {t("module_description")}
            </div>

            <br />
            <section className="body-font">
              <div className="flex flex-col">
                <div className="h-1 bg-gray-800 rounded overflow-hidden">
                  <div className="w-24 h-full bg-indigo-500"></div>
                </div>
              </div>
              <div className="px-5 py-24 mx-auto">
                <div className="flex flex-col">
                  <div className="h-1 bg-gray-900 rounded overflow-hidden">
                    <div className="w-24 h-full bg-indigo-500"></div>
                  </div>
                </div>
                {isAuthenticated ? <div className="row">{Links}</div> : ""}
              </div>
            </section>
          </div>
          <br />
          <hr />
          <br />

          <Statistics />
          <ResetPasswordForm
            visible={showResetPassword}
            onHide={() => setShowResetPassword(false)}
          />
        </div>
      </div>
    </>
  );
};

export default withTranslation(["main", "common"])(MainModule);
