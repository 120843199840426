import { useState } from "react";
import CreateHousehold from "./CreateHousehold";
import { useFormContext } from "react-hook-form";
import CheckBox from "./ControlledCheckBox";

import { FormInput } from "./types";

const HouseholdForm = () => {
  const {
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<FormInput>();

  const registerHousehold = getValues("has_household");
  watch("has_household");

  return (
    <div>
      {!registerHousehold ? (
        <div>
          <div className="flex align-items-center">
            <CheckBox
              name="has_household"
              label=" would you like to to register your household?"
            />
          </div>
        </div>
      ) : (
        <CreateHousehold />
      )}
    </div>
  );
};

export default HouseholdForm;
