import style from "./RoleSelector.module.css";
import { Dropdown } from "primereact/dropdown";
import { useRegisterForm } from "./context";
import { Chip } from "primereact/chip";
import { useState } from "react";

const RoleSelector = (props: {}) => {
  const { userRoles, userRole, setUserRole } = useRegisterForm();
  const [canSelect, setCanSelect] = useState(false);

  return (
    <div>
      {!canSelect && (
        <Chip
          label={userRole?.name}
          removable
          onRemove={() => setCanSelect(!canSelect)}
        />
      )}

      {canSelect && (
        <Dropdown
          panelClassName={style.select}
          value={userRole}
          onChange={(e) => {
            setUserRole(e.value);
            setCanSelect(!canSelect);
          }}
          options={userRoles}
          optionLabel="name"
          placeholder="Select a Role"
        />
      )}
    </div>
  );
};

export default RoleSelector;
