import { Button } from "primereact/button";
import { ReactElement, useState } from "react";
import style from "./Stepper.module.css";

type StepperProps = {
  title?: string;
  currentStep?: number;
  panels: ReactElement[];
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
  onBack?: () => void;
  onNext?: () => void;
  onFinish?: () => void;
};
const Stepper = ({
  title,
  panels,
  currentStep = 0,
  leftButtonDisabled,
  rightButtonDisabled,
  onNext,
  onBack,
  onFinish,
}: StepperProps) => {
  const isLastStep = currentStep === panels.length - 1;

  const Panel = panels[currentStep];

  return (
    <div>
      <div>{title && <h1>{title}</h1>}</div>

      {Panel}

      <div className={style.actionButton}>
        <Button
          disabled={leftButtonDisabled}
          icon="pi pi-arrow-left"
          className={style.button}
          severity="danger"
          label="Back"
          onClick={onBack}
        />

        <Button
          iconPos="right"
          disabled={rightButtonDisabled}
          icon={isLastStep ? "pi pi-save" : "pi pi-arrow-right"}
          className={style.button}
          severity="info"
          label={isLastStep ? "Finish" : "Next"}
          onClick={isLastStep ? onFinish : onNext}
        />
      </div>
    </div>
  );
};

export default Stepper;
