import { Area } from "../../../types";

export enum MapMarkStatus {
  Marked = "success",
  Pending = "warning",
  Unmarked = "danger",
}

export interface LocationArea {
  district: Area;
  ta: Area;
  village: Area;
  fullname: string;
}
