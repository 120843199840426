import { useRegisterForm } from "./context";
import { Dialog } from "primereact/dialog";
import { FORMS_BY_ROLE, StandardSteps } from "./constants";
import Stepper from "./Stepper";

import style from "./Modal.module.css";
import RoleSelector from "./RoleSelector";
import { FormProvider, useForm } from "react-hook-form";
import { FormInput } from "./types";
import { createUser } from "../../../app_modules/endpoints";
type RegisterFormProps = {};

const Modal = (props: RegisterFormProps) => {
  const methods = useForm<FormInput>({
    defaultValues: {
      members: [],
    },
    mode: "onBlur",
  });

  const { handleSubmit } = methods;

  const { userRole, visible, onHide, formCurrentStep, setFormCurrentStep } =
    useRegisterForm();

  const handleNext = () => {
    handleSubmit((data) => {
      setFormCurrentStep(formCurrentStep + 1);
    })();
  };

  const handleFormSubmit = () => {
    handleSubmit((data) => {
      const requestParams = {
        ...data,
        user_type: userRole ? userRole.id : 7,
        village_id: data.village?.id,
        enterprises: data.enterprises?.map((enterprise) => enterprise.id),
        cooperative_id: data.cooperative?.id,
      };
      createUser(requestParams)
        .then((res) => {
          onHide();
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  };

  return (
    <Dialog
      draggable={true}
      headerStyle={{
        textAlign: "center",
      }}
      onHide={onHide}
      visible={visible}
      maximizable={true}
      className="col-10 col-md-4"
    >
      <div className={style.container}>
        <div>
          <RoleSelector />
        </div>
        <FormProvider {...methods}>
          {userRole && (
            <Stepper
              leftButtonDisabled={formCurrentStep === 0}
              currentStep={formCurrentStep}
              onNext={handleNext}
              onBack={() => setFormCurrentStep(formCurrentStep - 1)}
              onFinish={handleFormSubmit}
              panels={[...StandardSteps, ...FORMS_BY_ROLE[userRole?.slug]]}
            />
          )}
        </FormProvider>
      </div>
    </Dialog>
  );
};

export default Modal;
