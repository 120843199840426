import { useFormContext } from "react-hook-form";
import style from "./PersonalInfoForm.module.css";
import SelectButton from "./ControlledSelectButton";
import { GenderOptions } from "./constants";
import { FormInput } from "./types";
import TextInput from "../../TextInput";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";

const PersonalInfoForm = (props: any) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<FormInput>();

  return (
    <div className={style.container}>
      <TextInput
        {...register("first_name", { required: "First name is required" })}
        placeholder="First name"
        invalid={Boolean(errors?.first_name?.message)}
        errorMessage={errors?.first_name?.message}
      />
      <TextInput
        {...register("last_name", { required: "Last name is required" })}
        placeholder="Last name"
        invalid={Boolean(errors?.last_name?.message)}
        errorMessage={errors?.last_name?.message}
      />

      <div className="p-inputgroup flex-1 w-full">
        <span className="p-inputgroup-addon">
          <i className="pi pi-at"></i>
        </span>
        <InputText
          {...register("email", {
            required: "Email is required",
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Please enter a valid email",
            },
          })}
          placeholder="Email"
          keyfilter="email"
          invalid={Boolean(errors?.email?.message)}
        />
      </div>

      <div className="p-inputgroup flex-1">
        <span className="p-inputgroup-addon">+265</span>
        <InputMask
          {...register("phone_number", {
            required: "Phone number is required",
          })}
          placeholder="Phone number"
          invalid={Boolean(errors?.phone_number?.message)}
          mask="(999) 999 999"
        />
      </div>

      <InputMask
        {...register("national_id", {
          onBlur: (e) => {
            setValue("national_id", e.target.value.toUpperCase());
          },
        })}
        placeholder="National ID"
        mask="********"
      />

      <div className="p-inputgroup flex-1">
        <span className="p-inputgroup-addon">+265</span>
        <InputMask
          {...register("secondary_phone", {
            required: false,
          })}
          placeholder="Secondary phone number"
          mask="(999) 999 999"
        />
      </div>

      <SelectButton
        name="gender"
        options={GenderOptions}
        rules={{ required: "Gender is required" }}
        label="Gender"
        errorMessage={errors?.gender?.message}
      />

      <div className="p-inputgroup flex-1">
        <span className="p-inputgroup-addon">
          <i className="pi pi-calendar"></i>
        </span>
        <InputMask
          {...register("dob", {
            required: "Date of birth is required",
          })}
          placeholder="Date of birth"
          invalid={Boolean(errors?.dob?.message)}
          mask="99/99/9999"
          slotChar="mm/dd/yyyy"
        />
      </div>
    </div>
  );
};

export default PersonalInfoForm;
