import { FC, useEffect, useRef, useState } from "react";
import UserList from "./UserList";
import { deleteUser, getUsersList } from "../endpoints";
import { User } from "../../context/types";
import { Pagination } from "../../types";
import { useAuth } from "../../context/AuthContext";
import { Toast } from "primereact/toast";

const UserContainer: FC<{}> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const { user: loggedInUser } = useAuth();
  const toast = useRef(null);
  const [pagination, setPagination] = useState<Pagination>({
    count: 1,
    limit: 10,
    offset: 0,
  });

  const handleDeleteUser = (id: string) => {
    if (!toast.current) {
      return;
    }
    if (loggedInUser?.id === id) {
      (toast.current as Toast).show({
        severity: "error",
        summary: "Error",
        detail: "You can't delete yourself",
      });
      return;
    }

    deleteUser(id).then(() => {
      if (!toast.current) {
        return;
      }
      (toast.current as Toast).show({
        severity: "success",
        summary: "Info",
        detail: "User deleted successfully",
      });
      setUsers(users.filter((user) => user.id !== id));
    });
  };

  const fetchUsers = () => {
    setLoading(true);
    getUsersList(pagination.offset)
      .then((res) => {
        setLoading(false);
        setPagination({
          count: parseInt(res.count),
          limit: parseInt(res.limit),
          offset: parseInt(res.offset),
        });
        setUsers(res.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [pagination.offset, users.length]);

  return (
    <div>
      <Toast ref={toast} />
      <UserList
        users={users}
        pagination={pagination}
        setPagination={setPagination}
        deleteUser={handleDeleteUser}
        refetchUsers={fetchUsers}
      />
    </div>
  );
};

export default UserContainer;
