import { Checkbox } from "primereact/checkbox";
import { Controller, useFormContext } from "react-hook-form";

type ControlledCheckBoxProps = {
  name: string;
  label: string;
};
const ControlledCheckBox = (props: ControlledCheckBoxProps) => {
  const { name, label } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex align-items-center gap-2">
      <label htmlFor={name} className="ml-2">
        {label}
      </label>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <Checkbox
              checked={value}
              color="info"
              invalid={Boolean(errors?.[name])}
              onClick={(e) => {
                onChange(!value);
              }}
            />
          );
        }}
        name={name}
      />
    </div>
  );
};

export default ControlledCheckBox;
