import { useEffect, useState } from "react";
import SearchInput from "./SearchInput";
import { searchVillages } from "../../../app_modules/endpoints";
import { AutoCompleteProps } from "primereact/autocomplete";

type SearchInputContainerProps = {
  onNotFoundClick?: () => void;
  name: string;
  rules?: Record<string, any>;
  errorMessage?: string;
} & AutoCompleteProps;

const SearchInputContainer = (props: SearchInputContainerProps) => {
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const fetchData = () => {
      searchVillages(query).then((res) => {
        setItems(res);
      });
    };
    if (query.length > 2) {
      fetchData();
    }
  }, [query]);

  return (
    <SearchInput
      searchItems={items}
      query={query}
      setQuery={setQuery}
      {...props}
    />
  );
};

export default SearchInputContainer;
