import { InputText } from "primereact/inputtext";
import { useForm, SubmitHandler, useFormContext } from "react-hook-form";
import SelectButton from "./ControlledSelectButton";
import style from "./CreateHousehold.module.css";
import { EducationLevel, MartialStatusOptions } from "./constants";
import Dropdown from "./DropdownContainer";
import {
  getCooperatives,
  getEnterprises,
} from "../../../app_modules/endpoints";
import { useState } from "react";
import MemberCreate from "./MemberCreate";
import LandHoldingForm from "./LandHoldingForm";
import NGOForm from "./NGOForm";
import GovernmentProjectForm from "./GovernmentProjectForm";
import { AccordionTab, Accordion } from "primereact/accordion";
import TextInput from "../../TextInput";

const CreateHouseHold = (props: any) => {
  const [canAddMember, setCanAddMember] = useState<boolean>(false);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={style.container}>
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab header="Basic detail">
          <div className={style.basicDetailsWrapper}>
            <TextInput
              {...register("stakeholder_panel")}
              placeholder="Area stakeholder panel"
            />
            <SelectButton
              className={style.selectButton}
              name="marital_status"
              options={MartialStatusOptions}
              label="Marital status"
            />

            <SelectButton
              className={style.selectButton}
              name="education_level"
              options={EducationLevel}
              label="Education"
            />
            <Dropdown
              panelClassName={style.select}
              name="cooperative"
              label="Cooperatives"
              optionLabel="name"
              fetch={getCooperatives}
            />
            <Dropdown
              name="enterprises"
              label="Enterprises"
              optionLabel="name"
              fetch={getEnterprises}
              multiple={true}
            />
          </div>
        </AccordionTab>

        <AccordionTab header="Household members">
          <MemberCreate />
        </AccordionTab>
        <AccordionTab header="Land holding">
          <LandHoldingForm />
        </AccordionTab>

        <AccordionTab header="NGO Support">
          <NGOForm />
        </AccordionTab>

        <AccordionTab header="Government Support">
          <GovernmentProjectForm />
        </AccordionTab>
      </Accordion>
    </div>
  );
};

const CollapsedMembers = ({ members }: { members: any[] }) => {
  return (
    <div className={style.collapsedMembers}>
      {members.map((member: any, index: number) => {
        return (
          <div key={index}>
            <span>{member?.first_name}</span>
          </div>
        );
      })}
    </div>
  );
};
export default CreateHouseHold;
