import { FC, useState } from "react";
import style from "../style.module.css";
import { User } from "../../../context/types";
// import "../../messaging/messaging.css";

type UserActionBtnProp = {
  user: User;
  onDelete?: () => void;
};

const UserActionBtn: FC<UserActionBtnProp> = ({ user, onDelete }) => {
  return (
    <div>
      <button className={`${style.actionButton} ${style.blueColor}`}>
        <i className="pi pi-pencil"></i>
      </button>
      <button
        className={`${style.actionButton} ${style.redColor}`}
        onClick={onDelete}
      >
        <i className="pi pi-trash"></i>
      </button>
    </div>
  );
};

export default UserActionBtn;
